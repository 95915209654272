<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Edit Digitalisasi CV",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      await router.isReady();
      //console.log("route.path", route.path);
    });

    return { route };
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Digitalisasi CV",
      items: [
        {
          text: "Digitalisasi",
          href: "/",
        },
        {
          text: "Digitalisasi CV Direksi & Dekom",
          href: "/digitaliasi/cv",
        },
        {
          text: "Edit",
          active: true,
        },
      ],

      AddModal: false,
      QRCodeModal: false,
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      token: this.$route.params.token,
      tanggal_print: "",
      lokasi: "",
      // upload file
      file: null,
      // tabs Ket Perorangan
      master_user: [],
      master_user_selected: [],
      //jabatan
      cv_riwayat_jabatan: [],
      cv_riwayat_jabatan_db: 0,
      //penugasan
      cv_penugasan: [],
      cv_penugasan_db: 0,
      // organisasi 1
      cv_organisasi_pekerjaan: [],
      cv_organisasi_pekerjaan_db: 0,
      // organisasi 2
      cv_organisasi_nonformal: [],
      cv_organisasi_nonformal_db: 0,
      // penghargaan
      cv_penghargaan: [],
      cv_penghargaan_db: 0,
      // pendidikan 1
      cv_pendidikan_formal: [],
      cv_pendidikan_formal_db: 0,
      // pendidikan 2
      cv_diklat_jabatan: [],
      cv_diklat_jabatan_db: 0,
      cv_diklat_fungsional: [],
      cv_diklat_fungsional_db: 0,
      //karya
      cv_karya_tulis: [],
      cv_karya_tulis_db: 0,
      //pengalaman
      cv_pengalaman_pembicara: [],
      cv_pengalaman_pembicara_db: 0,
      cv_referensi: [],
      cv_referensi_db: 0,
      // istrisuami
      cv_pasangan: [],
      cv_pasangan_db: 0,
      // istrisuami
      cv_anak: [],
      cv_anak_db: 0,

      // display data
      cv_id: "",
      cv_nama: "",
      cv_gambar: "",
      cv_gelar_akademik: [],
      cv_pn: "",
      cv_nik: "",
      cv_tempat_lahir: "",
      cv_tanggal_lahir: "",
      cv_jenis_kelamin: "",
      cv_agama: "",
      cv_jabatan_terakhir: "",
      cv_alamat_rumah: "",
      cv_hp: "",
      cv_email: "",
      cv_alamat_social_media: "",
      sosmed: "",
      cv_summary: "",
      cv_interest: "",
      cv_permintaan_posisi_direktur: [],
      link_pas_foto: "",
      base_url: process.env.VUE_APP_BACKEND_URL_VERSION,
      cv_catatan: "",

      barcode_link: "",
      wizard_now: "nav-perorangan",
      wizard_next: "",
      permintaan_posisi_lainnya: "",

      //upload konfigurasi
      max_upload_size: 0,
      label_upload_tipe: "*",
      textlabel_upload_tipe: "all",
    };
  },
  mounted() {
    this.getCV();
    this.getKonfig();
    this.getAkses();
    this.getBarcode();
    this.getKonfigurasiEkstensi();
  },
  methods: {
    getCV() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/digitalisasi/cv/detail?i=" +
          self.token
        )
        .then((response) => {
          var response_data = response;
          var response_data_fix = response_data.data.data;
          if (response_data.status == 200) {
            self.cv_id = response_data_fix.cv_id;
            self.cv_nama = response_data_fix.cv_nama;
            self.cv_gambar = response_data_fix.cv_gambar;
            self.permintaan_posisi_lainnya =
              response_data_fix.cv_permintaan_posisi_direktur_lain;
            self.cv_pn = response_data_fix.cv_pn;
            self.cv_nik = response_data_fix.cv_nik;
            self.cv_tempat_lahir = response_data_fix.cv_tempat_lahir;
            self.cv_tanggal_lahir = response_data_fix.cv_tanggal_lahir;
            self.cv_jenis_kelamin = response_data_fix.cv_jenis_kelamin;
            self.cv_agama = response_data_fix.cv_agama;
            self.cv_jabatan_terakhir = response_data_fix.cv_jabatan_terakhir;
            self.cv_alamat_rumah = response_data_fix.cv_alamat_rumah;
            self.cv_hp = response_data_fix.cv_hp;
            self.cv_email = response_data_fix.cv_email;
            self.cv_npwp = response_data_fix.cv_npwp;
            self.cv_alamat_social_media =
              response_data_fix.cv_alamat_social_media;
            self.cv_summary = response_data_fix.cv_summary;
            self.cv_interest = response_data_fix.cv_interest;

            var dir_name = response_data_fix.cv_gambar;
            var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
            urlres += dir_name;
            self.link_pas_foto = urlres;
            // self.cv_permintaan_posisi_direktur = response_data_fix.cv_permintaan_posisi_direktur;

            // GELAR AKADEMIK
            if (response_data_fix.cv_gelar_akademik) {
              let clear_data_json_response_cv_gelar_akademik = [];

              var json_response_cv_gelar_akademik = JSON.parse(response_data_fix.cv_gelar_akademik);
              $.each(
                json_response_cv_gelar_akademik,
                function (indexInArray, valueOfElement) {
                  clear_data_json_response_cv_gelar_akademik.push({
                    nama_gelar: valueOfElement,
                  });
                }
              );

              let merged_array_cv_gelar_akademik = [].concat.apply([], clear_data_json_response_cv_gelar_akademik);
              self.cv_gelar_akademik = merged_array_cv_gelar_akademik;
            }
            console.log(self.cv_gelar_akademik);

            // PERMINTAAN POSISI DIREKTUR
            if (response_data_fix.cv_permintaan_posisi_direktur) {
              let clear_data_json_response_cv_permintaan_posisi_direktur = [];

              var json_response_cv_permintaan_posisi_direktur = JSON.parse(response_data_fix.cv_permintaan_posisi_direktur);
              $.each(json_response_cv_permintaan_posisi_direktur, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_permintaan_posisi_direktur.push(valueOfElement);
              });

              let merged_array_cv_permintaan_posisi_direktur = [].concat.apply([], clear_data_json_response_cv_permintaan_posisi_direktur);
              self.cv_permintaan_posisi_direktur = merged_array_cv_permintaan_posisi_direktur;
            }
            console.log(self.cv_permintaan_posisi_direktur);

            // RIWAYAT JABATAN
            if (response_data_fix.cv_riwayat_jabatan) {
              let clear_data_json_response_cv_riwayat_jabatan = [];

              var json_response_cv_riwayat_jabatan = JSON.parse(response_data_fix.cv_riwayat_jabatan);
              $.each(json_response_cv_riwayat_jabatan, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_riwayat_jabatan.push({
                  jabatan: valueOfElement.jabatan,
                  uraian: valueOfElement.uraian,
                  rentang_waktu: valueOfElement.rentang_waktu,
                  achievement: valueOfElement.achievement,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_riwayat_jabatan = [].concat.apply([], clear_data_json_response_cv_riwayat_jabatan);
              self.cv_riwayat_jabatan = merged_array_cv_riwayat_jabatan;
              self.cv_riwayat_jabatan_db = self.cv_riwayat_jabatan.length;
            }
            console.log(self.cv_diklat_jabatan);

            // PENUGASAN
            if (response_data_fix.cv_penugasan) {
              let clear_data_json_response_cv_penugasan = [];

              var json_response_cv_penugasan = JSON.parse(response_data_fix.cv_penugasan);
              $.each(json_response_cv_penugasan, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_penugasan.push({
                  penugasan: valueOfElement.tupoksi,
                  tupoksi: valueOfElement.tupoksi,
                  rentang_waktu: valueOfElement.rentang_waktu,
                  instansi: valueOfElement.instansi,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_penugasan = [].concat.apply([], clear_data_json_response_cv_penugasan);
              self.cv_penugasan = merged_array_cv_penugasan;
              self.cv_penugasan_db = self.cv_penugasan.length;
            }
            console.log(self.cv_penugasan);

            // ORGANISASI PEKERJAAN
            if (response_data_fix.cv_organisasi_pekerjaan) {
              let clear_data_json_response_cv_organisasi_pekerjaan = [];

              var json_response_cv_organisasi_pekerjaan = JSON.parse(response_data_fix.cv_organisasi_pekerjaan);
              $.each(json_response_cv_organisasi_pekerjaan, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_organisasi_pekerjaan.push({
                  nama: valueOfElement.nama,
                  jabatan: valueOfElement.jabatan,
                  rentang_waktu: valueOfElement.rentang_waktu,
                  uraian: valueOfElement.uraian,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_organisasi_pekerjaan = [].concat.apply([], clear_data_json_response_cv_organisasi_pekerjaan);
              self.cv_organisasi_pekerjaan = merged_array_cv_organisasi_pekerjaan;
              self.cv_organisasi_pekerjaan_db = self.cv_organisasi_pekerjaan.length;
            }
            console.log(self.cv_organisasi_pekerjaan);

            // ORGANISASI NON FORMAL
            if (response_data_fix.cv_organisasi_nonformal) {
              let clear_data_json_response_cv_organisasi_nonformal = [];

              var json_response_cv_organisasi_nonformal = JSON.parse(response_data_fix.cv_organisasi_nonformal);
              $.each(json_response_cv_organisasi_nonformal, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_organisasi_nonformal.push({
                  nama: valueOfElement.nama,
                  jabatan: valueOfElement.jabatan,
                  rentang_waktu: valueOfElement.rentang_waktu,
                  uraian: valueOfElement.uraian,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_organisasi_nonformal = [].concat.apply([], clear_data_json_response_cv_organisasi_nonformal);
              self.cv_organisasi_nonformal = merged_array_cv_organisasi_nonformal;
              self.cv_organisasi_nonformal_db = self.cv_organisasi_nonformal.length;
            }
            console.log(self.cv_organisasi_nonformal);

            // PENGHARGAAN
            if (response_data_fix.cv_penghargaan) {
              let clear_data_json_response_cv_penghargaan = [];

              var json_response_cv_penghargaan = JSON.parse(response_data_fix.cv_penghargaan);
              $.each(json_response_cv_penghargaan, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_penghargaan.push({
                  jenis: valueOfElement.jenis,
                  tingkat: valueOfElement.tingkat,
                  diberikan_oleh: valueOfElement.diberikan_oleh,
                  tahun: valueOfElement.tahun,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_penghargaan = [].concat.apply([], clear_data_json_response_cv_penghargaan);
              self.cv_penghargaan = merged_array_cv_penghargaan;
              self.cv_penghargaan_db = self.cv_penghargaan.length;
            }
            console.log(self.cv_penghargaan);

            // PENDIDIKAN FORMAL
            if (response_data_fix.cv_pendidikan_formal) {
              let clear_data_json_response_cv_pendidikan_formal = [];

              var json_response_cv_pendidikan_formal = JSON.parse(response_data_fix.cv_pendidikan_formal);
              $.each(json_response_cv_pendidikan_formal, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_pendidikan_formal.push({
                  jenjang: valueOfElement.jenjang,
                  perguruan_tinggi: valueOfElement.perguruan_tinggi,
                  tahun_lulus: valueOfElement.tahun_lulus,
                  kota: valueOfElement.kota,
                  penghargaan: valueOfElement.penghargaan,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_pendidikan_formal = [].concat.apply([], clear_data_json_response_cv_pendidikan_formal);
              self.cv_pendidikan_formal = merged_array_cv_pendidikan_formal;
              self.cv_pendidikan_formal_db = self.cv_pendidikan_formal.length;
            }
            console.log(self.cv_pendidikan_formal);

            // DIKLAT JABATAN
            if (response_data_fix.cv_diklat_jabatan) {
              let clear_data_json_response_cv_diklat_jabatan = [];

              var json_response_cv_diklat_jabatan = JSON.parse(response_data_fix.cv_diklat_jabatan);
              $.each(json_response_cv_diklat_jabatan, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_diklat_jabatan.push({
                  nama: valueOfElement.nama,
                  penyelenggara: valueOfElement.penyelenggara,
                  durasi: valueOfElement.durasi,
                  nomor_sertifikat: valueOfElement.nomor_sertifikat,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_diklat_jabatan = [].concat.apply([], clear_data_json_response_cv_diklat_jabatan);
              self.cv_diklat_jabatan = merged_array_cv_diklat_jabatan;
              self.cv_diklat_jabatan_db = self.cv_diklat_jabatan.length;
            }
            console.log(self.cv_diklat_jabatan);

            // DIKLAT FUNGSIONAL
            if (response_data_fix.cv_diklat_fungsional) {
              let clear_data_json_response_cv_diklat_fungsional = [];

              var json_response_cv_diklat_fungsional = JSON.parse(response_data_fix.cv_diklat_fungsional);
              $.each(json_response_cv_diklat_fungsional, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_diklat_fungsional.push({
                  nama: valueOfElement.nama,
                  penyelenggara: valueOfElement.penyelenggara,
                  durasi: valueOfElement.durasi,
                  nomor_sertifikat: valueOfElement.nomor_sertifikat,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_diklat_fungsional = [].concat.apply([], clear_data_json_response_cv_diklat_fungsional);
              self.cv_diklat_fungsional = merged_array_cv_diklat_fungsional;
              self.cv_diklat_fungsional_db = self.cv_diklat_fungsional.length;
            }
            console.log(self.cv_diklat_fungsional);

            // KARYA TULIS
            if (response_data_fix.cv_karya_tulis) {
              let clear_data_json_response_cv_karya_tulis = [];

              var json_response_cv_karya_tulis = JSON.parse(response_data_fix.cv_karya_tulis);
              $.each(json_response_cv_karya_tulis, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_karya_tulis.push({
                  judul: valueOfElement.judul,
                  tahun: valueOfElement.tahun,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_karya_tulis = [].concat.apply([], clear_data_json_response_cv_karya_tulis);
              self.cv_karya_tulis = merged_array_cv_karya_tulis;
              self.cv_karya_tulis_db = self.cv_karya_tulis.length;
            }
            console.log(self.cv_karya_tulis);

            // PENGALAMAN PEMBICARA
            if (response_data_fix.cv_pengalaman_pembicara) {
              let clear_data_json_response_cv_pengalaman_pembicara = [];

              var json_response_cv_pengalaman_pembicara = JSON.parse(response_data_fix.cv_pengalaman_pembicara);
              $.each(json_response_cv_pengalaman_pembicara, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_pengalaman_pembicara.push({
                  acara: valueOfElement.acara,
                  penyelenggara: valueOfElement.penyelenggara,
                  periode: valueOfElement.periode,
                  lokasi: valueOfElement.lokasi,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_pengalaman_pembicara = [].concat.apply([], clear_data_json_response_cv_pengalaman_pembicara);
              self.cv_pengalaman_pembicara = merged_array_cv_pengalaman_pembicara;
              self.cv_pengalaman_pembicara_db = self.cv_pengalaman_pembicara.length;
            }
            console.log(self.cv_pengalaman_pembicara);

            // REFERENSI
            if (response_data_fix.cv_referensi) {
              let clear_data_json_response_cv_referensi = [];

              var json_response_cv_referensi = JSON.parse(response_data_fix.cv_referensi);
              $.each(json_response_cv_referensi, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_referensi.push({
                  nama: valueOfElement.nama,
                  perusahaan: valueOfElement.perusahaan,
                  jabatan: valueOfElement.jabatan,
                  no_telp: valueOfElement.no_telp,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_referensi = [].concat.apply([], clear_data_json_response_cv_referensi);
              self.cv_referensi = merged_array_cv_referensi;
              self.cv_referensi_db = self.cv_referensi.length;
            }
            console.log(self.cv_referensi);

            // PASANGAN
            if (response_data_fix.cv_pasangan) {
              let clear_data_json_response_cv_pasangan = [];

              var json_response_cv_pasangan = JSON.parse(response_data_fix.cv_pasangan);
              $.each(json_response_cv_pasangan, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_pasangan.push({
                  nama: valueOfElement.nama,
                  tempat_lahir: valueOfElement.tempat_lahir,
                  tanggal_lahir: valueOfElement.tanggal_lahir,
                  tanggal_menikah: valueOfElement.tanggal_menikah,
                  pekerjaan: valueOfElement.pekerjaan,
                  keterangan: valueOfElement.keterangan,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_pasangan = [].concat.apply([], clear_data_json_response_cv_pasangan);
              self.cv_pasangan = merged_array_cv_pasangan;
              self.cv_pasangan_db = self.cv_pasangan.length;
            }
            console.log(self.cv_pasangan);

            // ANAK
            if (response_data_fix.cv_anak) {
              let clear_data_json_response_cv_anak = [];

              var json_response_cv_anak = JSON.parse(response_data_fix.cv_anak);
              $.each(json_response_cv_anak, function (indexInArray, valueOfElement) {
                clear_data_json_response_cv_anak.push({
                  nama: valueOfElement.nama,
                  tempat_lahir: valueOfElement.tempat_lahir,
                  tanggal_lahir: valueOfElement.tanggal_lahir,
                  jenis_kelamin: valueOfElement.jenis_kelamin,
                  pekerjaan: valueOfElement.pekerjaan,
                  keterangan: valueOfElement.keterangan,
                  file: valueOfElement.file,
                });
              });

              let merged_array_cv_anak = [].concat.apply([], clear_data_json_response_cv_anak);
              self.cv_anak = merged_array_cv_anak;
              self.cv_anak_db = self.cv_anak.length;
            }
            console.log(self.cv_anak);

          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
    },

    getBarcode() {
      let self = this;
      var FormData = require("form-data");
      var data = new FormData();
      var urlres = process.env.VUE_APP_URL;
      urlres += self.route.path;
      data.append("link", urlres);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/barcodehandle/barcode?id=" +
          self.cv_id,
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var barcode_url = process.env.VUE_APP_BACKEND_URL_VERSION;
          barcode_url += response_data.barcode;
          self.barcode_link = barcode_url;
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
        });
    },


    getKonfig() {
      let self = this;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/konfig")
        .then((response) => {
          var response_data = response.data;
          //console.log(response_data);
          if (response_data.code == 200) {
            self.cv_catatan = response_data.list_data.data[7].value;
            //console.log(self.cv_catatan);
          }
        });
    },

    getKonfigurasiEkstensi() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/konfigurasi_ekstensi?menu=digitalisasi"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            var data_ekstensi = response_data.list_data.data[0];
            var ekstensi = JSON.parse(data_ekstensi.value_extension);
            self.label_upload_tipe = ekstensi
              .map((value) => value.label)
              .join(",");
            self.textlabel_upload_tipe = ekstensi
              .map((value) => value.value)
              .join(",");
            self.max_upload_size = data_ekstensi.max_upload_size;
          }
        });
    },

    getAkses() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/akses")
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    handleFileUpload(event) {
      let self = this;
      self.file = event.target.files[0];
    },
    addRowGelar() {
      let self = this;
      self.cv_gelar_akademik.push({
        nama_gelar: null,
      });
    },
    removeRowGelar(key_deleted) {
      this.cv_gelar_akademik.splice(key_deleted, 1);
    },
    // jabatan
    addjabatan() {
      let self = this;
      self.cv_riwayat_jabatan.push({
        jabatan: "",
        uraian: "",
        rentang_waktu: "",
        achievement: "",
        file: "",
      });
    },
    removejabatan(index) {
      let self = this;
      self.cv_riwayat_jabatan.splice(index, 1);
    },
    // penugasan
    addpenugasan() {
      let self = this;
      self.cv_penugasan.push({
        penugasan: "",
        tupoksi: "",
        rentang_waktu: "",
        instansi: "",
        file: "",
      });
    },
    removepenugasan(index) {
      let self = this;
      self.cv_penugasan.splice(index, 1);
    },
    //  organisasi formal di beri nama organisasi 1
    addorganisasi1() {
      let self = this;
      self.cv_organisasi_pekerjaan.push({
        nama: "",
        jabatan: "",
        rentang_waktu: "",
        uraian: "",
        file: "",
      });
    },
    removeorganisasi1(index) {
      let self = this;
      self.cv_organisasi_pekerjaan.splice(index, 1);
    },
    //  organisasi non formal di beri nama organisasi 2
    addorganisasi2() {
      let self = this;
      self.cv_organisasi_nonformal.push({
        nama: "",
        jabatan: "",
        rentang_waktu: "",
        uraian: "",
        file: "",
      });
    },
    removeorganisasi2(index) {
      let self = this;
      self.cv_organisasi_nonformal.splice(index, 1);
    },
    //  organisasi non formal di beri nama organisasi 2
    addpenghargaan() {
      let self = this;
      self.cv_penghargaan.push({
        jenis: "",
        tingkat: "",
        diberikan_oleh: "",
        tahun: "",
        file: "",
      });
    },
    removepenghargaan(index) {
      let self = this;
      self.cv_penghargaan.splice(index, 1);
    },
    //  pendidikan formal di beri nama pendidikan 1
    addpendidikan() {
      let self = this;
      self.cv_pendidikan_formal.push({
        jenjang: "",
        perguruan_tinggi: "",
        tahun_lulus: "",
        kota: "",
        penghargaan: "",
        file: "",
      });
    },
    removependidikan(index) {
      let self = this;
      self.cv_pendidikan_formal.splice(index, 1);
    },
    //  pendidikan formal di beri nama pendidikan 1
    adddiklat() {
      let self = this;
      self.cv_diklat_jabatan.push({
        nama: "",
        penyelenggara: "",
        durasi: "",
        nomor_sertifikat: "",
        file: "",
      });
    },
    removediklat(index) {
      let self = this;
      self.cv_diklat_jabatan.splice(index, 1);
    },
    adddiklatfungsional() {
      let self = this;
      self.cv_diklat_fungsional.push({
        nama: "",
        penyelenggara: "",
        durasi: "",
        nomor_sertifikat: "",
        file: "",
      });
    },
    removediklatfungsional(index) {
      let self = this;
      self.cv_diklat_fungsional.splice(index, 1);
    },
    //  pendidikan formal di beri nama pendidikan 1
    addkarya() {
      let self = this;
      self.cv_karya_tulis.push({
        judul: "",
        tahun: "",
        file: "",
      });
    },
    removekarya(index) {
      let self = this;
      self.cv_karya_tulis.splice(index, 1);
    },
    addpengalaman() {
      let self = this;
      self.cv_pengalaman_pembicara.push({
        acara: "",
        penyelenggara: "",
        periode: "",
        lokasi: "",
        file: "",
      });
    },
    removepengalaman(index) {
      let self = this;
      self.cv_pengalaman_pembicara.splice(index, 1);
    },
    addreferensi() {
      let self = this;
      self.cv_referensi.push({
        nama: "",
        perusahaan: "",
        jabatan: "",
        no_telp: "",
        file: "",
      });
    },
    removereferensi(index) {
      let self = this;
      self.cv_referensi.splice(index, 1);
    },
    addistrisuami() {
      let self = this;
      self.cv_pasangan.push({
        nama: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        tanggal_menikah: "",
        pekerjaan: "",
        keterangan: "",
        file: "",
      });
    },
    removeistrisuami(index) {
      let self = this;
      self.cv_pasangan.splice(index, 1);
    },
    addanak() {
      let self = this;
      self.cv_anak.push({
        nama: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        jenis_kelamin: "",
        pekerjaan: "",
        keterangan: "",
        file: "",
      });
    },
    removeanak(index) {
      let self = this;
      self.cv_pasangan.splice(index, 1);
    },

    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      if (self.cv_gambar == null) {
        self.cv_gambar = "";
      }
      if (self.cv_nama == null) {
        self.cv_nama = "";
      }
      if (self.cv_nik == null) {
        self.cv_nik = "";
      }
      if (self.cv_gelar_akademik == null) {
        self.cv_gelar_akademik = [];
      }
      if (self.cv_tempat_lahir == null) {
        self.cv_tempat_lahir = "";
      }
      if (self.cv_tanggal_lahir == null) {
        self.cv_tanggal_lahir = "";
      }
      if (self.cv_jenis_kelamin == null) {
        self.cv_jenis_kelamin = "";
      }
      if (self.cv_agama == null) {
        self.cv_agama = "";
      }
      if (self.cv_jabatan_terakhir == null) {
        self.cv_jabatan_terakhir = "";
      }
      if (self.cv_alamat_rumah == null) {
        self.cv_alamat_rumah = "";
      }
      if (self.cv_hp == null) {
        self.cv_hp = "";
      }
      if (self.cv_email == null) {
        self.cv_email = "";
      }
      if (self.cv_npwp == null) {
        self.cv_npwp = "";
      }
      if (self.cv_alamat_social_media == null) {
        self.cv_alamat_social_media = "";
      }
      if (self.cv_summary == null) {
        self.cv_summary = "";
      }
      if (self.cv_interest == null) {
        self.cv_interest = "";
      }
      if (self.cv_permintaan_posisi_direktur == null) {
        self.cv_permintaan_posisi_direktur = null;
      }
      if (self.cv_riwayat_jabatan == null) {
        self.cv_riwayat_jabatan = null;
      }
      if (self.cv_penugasan == null) {
        self.cv_penugasan = null;
      }
      if (self.cv_organisasi_pekerjaan == null) {
        self.cv_organisasi_pekerjaan = null;
      }
      if (self.cv_organisasi_nonformal == null) {
        self.cv_organisasi_nonformal = null;
      }
      if (self.cv_penghargaan == null) {
        self.cv_penghargaan = null;
      }
      if (self.cv_pendidikan_formal == null) {
        self.cv_pendidikan_formal = null;
      }
      if (self.cv_diklat_jabatan == null) {
        self.cv_diklat_jabatan = null;
      }
      if (self.cv_diklat_fungsional == null) {
        self.cv_diklat_fungsional = null;
      }
      if (self.cv_karya_tulis == null) {
        self.cv_karya_tulis = null;
      }
      if (self.cv_pengalaman_pembicara == null) {
        self.cv_pengalaman_pembicara = null;
      }
      if (self.cv_referensi == null) {
        self.cv_referensi = null;
      }
      if (self.cv_pasangan == null) {
        self.cv_pasangan = null;
      }
      if (self.cv_anak == null) {
        self.cv_anak = null;
      }
      if (self.cv_catatan == null) {
        self.cv_catatan = "";
      }
      data.append("id", self.cv_id);
      data.append("cv_nama", self.cv_nama);
      data.append("cv_gambar", self.cv_gambar);
      data.append("cv_gelar_akademik", JSON.stringify(self.cv_gelar_akademik));
      data.append("cv_nik", self.cv_nik);
      data.append("cv_pn", self.cv_pn);
      data.append("cv_tempat_lahir", self.cv_tempat_lahir);
      data.append("cv_tanggal_lahir", self.cv_tanggal_lahir);
      data.append("cv_jenis_kelamin", self.cv_jenis_kelamin);
      data.append("cv_agama", self.cv_agama);
      data.append("cv_jabatan_terakhir", self.cv_jabatan_terakhir);
      data.append("cv_alamat_rumah", self.cv_alamat_rumah);
      data.append("cv_hp", self.cv_hp);
      data.append("cv_email", self.cv_email);
      data.append("cv_npwp", self.cv_npwp);
      data.append("cv_alamat_social_media", self.cv_alamat_social_media);
      data.append("cv_summary", self.cv_summary);
      data.append("cv_interest", self.cv_interest);
      data.append(
        "cv_permintaan_posisi_direktur",
        JSON.stringify(self.cv_permintaan_posisi_direktur)
      );
      data.append(
        "cv_permintaan_posisi_lainnya",
        self.permintaan_posisi_lainnya
      );
      data.append(
        "cv_riwayat_jabatan",
        JSON.stringify(self.cv_riwayat_jabatan)
      );
      data.append("cv_penugasan", JSON.stringify(self.cv_penugasan));
      data.append(
        "cv_organisasi_pekerjaan",
        JSON.stringify(self.cv_organisasi_pekerjaan)
      );
      data.append(
        "cv_organisasi_nonformal",
        JSON.stringify(self.cv_organisasi_nonformal)
      );
      data.append("cv_penghargaan", JSON.stringify(self.cv_penghargaan));
      data.append(
        "cv_pendidikan_formal",
        JSON.stringify(self.cv_pendidikan_formal)
      );
      data.append("cv_diklat_jabatan", JSON.stringify(self.cv_diklat_jabatan));
      data.append(
        "cv_diklat_fungsional",
        JSON.stringify(self.cv_diklat_fungsional)
      );
      data.append("cv_karya_tulis", JSON.stringify(self.cv_karya_tulis));
      data.append(
        "cv_pengalaman_pembicara",
        JSON.stringify(self.cv_pengalaman_pembicara)
      );
      data.append("cv_referensi", JSON.stringify(self.cv_referensi));
      data.append("cv_pasangan", JSON.stringify(self.cv_pasangan));
      data.append("cv_anak", JSON.stringify(self.cv_anak));
      data.append("cv_catatan", self.cv_catatan);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/digitalisasi/cv/aksiadd",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman digitalisasi cv segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getCV();
                self.$router.push({ name: "cv" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    clickPasPoto() {
      $("#upload_pas_foto").trigger("click");
    },
    uploadPasFoto() {
      let self = this;
      if ($("#upload_pas_foto")[0].files[0]) {
        if ($("#upload_pas_foto")[0].files[0].size < 5000000) {
          $("#upload_loading_pas_foto").html(
              '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload_pas_foto")[0].files[0]);
          data.append("dir", "cv");
          data.append("accepted_file", "image/*");
          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#upload_loading_pas_foto").html(
                '<div class="btn btn-success btn-sm mt-1"><i class="fa fa-check"></i> Berhasil </div> <a href="' +
                urlres +
                '" target="_blank" class="btn btn-info btn-sm mt-1"><i class="fa fa-download"> Download</a>'
            );
            
            self.cv_gambar = dir_name;
            self.link_pas_foto = urlres;
            //console.log(dir_name);
            //console.log(urlres);
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: "Ukuran file upload melebihi 4.77 MB",
          });
        }
      }
    },
    uploadFileHandle(row, nama_dokumen, tipe) {
      let self = this;
      if ($("#upload_file_" + nama_dokumen + "" + row + "")[0].files[0]) {
        if (
          $("#upload_file_" + nama_dokumen + "" + row + "")[0].files[0].size <
          self.max_upload_size
        ) {
          $("#upload_loading_file_" + nama_dokumen + "" + row + "").html(
              '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();
          var url_files = "cv/" + self.cv_id + "/" + tipe;

          data.append(
            "file",
            $("#upload_file_" + nama_dokumen + "" + row + "")[0].files[0]
          );
          data.append("dir", url_files);
          data.append("accepted_file", self.label_upload_tipe);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#upload_loading_file_" + nama_dokumen + "" + row + "").html(
              '<div class="btn btn-success btn-sm mt-1"><i class="fa fa-check"></i> Berhasil </div> <a href="' +
              urlres +
              '" target="_blank" class="btn btn-info btn-sm mt-1"><i class="fa fa-download"> Download</a>'
            );

            if (nama_dokumen == "cv_riwayat_jabatan") {
              self.cv_riwayat_jabatan[row].file = dir_name;
            }
            if (nama_dokumen == "cv_penugasan") {
              self.cv_penugasan[row].file = dir_name;
            }
            if (nama_dokumen == "cv_organisasi_pekerjaan") {
              self.cv_organisasi_pekerjaan[row].file = dir_name;
            }
            if (nama_dokumen == "cv_organisasi_nonformal") {
              self.cv_organisasi_nonformal[row].file = dir_name;
            }
            if (nama_dokumen == "cv_penghargaan") {
              self.cv_penghargaan[row].file = dir_name;
            }
            if (nama_dokumen == "cv_pendidikan_formal") {
              self.cv_pendidikan_formal[row].file = dir_name;
            }
            if (nama_dokumen == "cv_diklat_jabatan") {
              self.cv_diklat_jabatan[row].file = dir_name;
            }
            if (nama_dokumen == "cv_diklat_fungsional") {
              self.cv_diklat_fungsional[row].file = dir_name;
            }
            if (nama_dokumen == "cv_karya_tulis") {
              self.cv_karya_tulis[row].file = dir_name;
            }
            if (nama_dokumen == "cv_pengalaman_pembicara") {
              self.cv_pengalaman_pembicara[row].file = dir_name;
            }
            if (nama_dokumen == "cv_referensi") {
              self.cv_referensi[row].file = dir_name;
            }
            if (nama_dokumen == "cv_pasangan") {
              self.cv_pasangan[row].file = dir_name;
            }
            if (nama_dokumen == "cv_anak") {
              self.cv_anak[row].file = dir_name;
            }
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              "Ukuran file upload melebihi " +
              this.formatBytes(self.max_upload_size),
          });
        }
      }
    },

    StoreDataAkses() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id", self.cv_id);
      data.append("akses", JSON.stringify(self.master_user_selected));

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/digitalisasi/cv/editakses",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman detail cv segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getCV();
                self.AddModal = false;
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },

    excel() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/api/digitalisasi/cv/excel?i=" +
        self.cv_id +
        "&link=" +
        "[" +
        self.barcode_link +
        "]";
      link.setAttribute("target", "_blank");
      link.click();
      //console.log("barcode_link", self.barcode_link);
    },

    print() {
      let self = this;
      const link = document.createElement("a");
      if (!self.tanggal_print) {
        self.tanggal_print = "";
      }
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/api/digitalisasi/cv/print?i=" +
        self.cv_id +
        "&tanggal_print=" +
        self.tanggal_print +
        "&lokasi=" +
        self.lokasi +
        "&link=" +
        "[" +
        self.barcode_link +
        "]";
      link.setAttribute("target", "_blank");
      link.click();
    },

    showQRCode() {
      let self = this;
      self.QRCodeModal = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.QRCodeModal = true;
        self.getBarcode();
      });
    },

    changeWizard(current, next) {
      // TAB MENU MOVE
      $("#" + current + "-tab").removeClass("active");
      $("#" + current + "-tab").attr("aria-selected", false);
      $("#" + next + "-tab").addClass("active");
      $("#" + next + "-tab").attr("aria-selected", true);

      // FORM TABLE MOVE
      $("#" + current).removeClass("active");
      $("#" + current).removeClass("show");
      $("#" + next).addClass("active");
      $("#" + next).addClass("show");
      this.wizard_now = next + "-tab";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="col-md-6"></div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col-md-2">
                      <label for="">Tanggal Print</label>
                      <input type="date" class="form-control" v-model="tanggal_print" />
                    </div>
                    <div class="col-md-2">
                      <label for="">Lokasi</label>
                      <input type="text" class="form-control" v-model="lokasi" />
                    </div>
                    <div class="col-md-8 text-left mt-4">
                      <div class="btn btn-success m-1" @click="print">
                        <i class="fa fa-print"></i> Print
                      </div>
                      <div class="btn btn-success m-1" @click="excel">
                        <i class="fas fa-file-excel"></i> Excel
                      </div>
                      <div class="btn btn-warning m-1" @click="AddModal = true">
                        <i class="fa fa-user"></i> Akses
                      </div>
                      <!-- <div class="btn btn-info m-1" v-on:click="showQRCode()">
                        <i class="fa fa-qrcode"></i> QR Code
                      </div> -->
                    </div>
                  </div>

                  &nbsp;
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4 class="text-center" style="text-decoration: underline; font-weight: bold">
                    DAFTAR RIWAYAT HIDUP
                  </h4>

                  <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                    <!-- perorangan -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">KET. PERORANGAN</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="4">I. KETERANGAN PERERORANGAN</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 1px">1</td>
                              <td style="width: 170px">Nama Lengkap</td>
                              <td>
                                <b-form-input type="text" v-model="cv_nama"></b-form-input>
                              </td>
                              <td class="text-center" rowspan="13" style="width: 1px; vertical-align: top">
                                <div v-if="cv_gambar">
                                  <img alt="" style="
                                      width: 204px;
                                      height: 322px;
                                      border: 1px solid #7b7b7b;
                                      margin-bottom: 10px;
                                      object-fit: cover;
                                    " id="img-cv" v-bind:src="link_pas_foto" />
                                </div>
                                <div v-else>
                                  <img alt="" style="
                                      width: 204px;
                                      height: 322px;
                                      border: 1px solid #7b7b7b;
                                      margin-bottom: 10px;
                                      object-fit: cover;
                                    " id="img-cv" src="@/assets/default.png" />
                                </div>
                                <button type="button" class="btn btn-primary btn-sm text-center"
                                  onclick="document.getElementById('upload_pas_foto').click()">
                                  Upload Pas Foto
                                </button>
                                <input type="file" class="form-control" id="upload_pas_foto" name="file" accept="image/*"
                                  style="display: none" @change="uploadPasFoto" />
                                <label style="color: red">(.jpg, .png , Ukuran Maksimal Upload: 4.77
                                  MB)</label>
                                <input type="hidden" v-model="cv_gambar" />
                                <div class="respond-input-file float-left" id="upload_loading_pas_foto"></div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td style="width: 170px text-center">
                                Gelar Akademik
                              </td>
                              <td>
                                <table class="table table-bordered table-striped">
                                  <tbody class="bg-light">
                                    <tr style="text-align: center">
                                      <td>
                                        <div class="btn btn-success btn-sm" v-on:click="addRowGelar()">
                                          <i class="bx bx-plus"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr style="text-align: center" v-for="(
                                        cv_gelar_akademik_value,
                                          cv_gelar_akademik_key
                                      ) in cv_gelar_akademik" :key="cv_gelar_akademik_key">
                                      <td>
                                        Strata {{ cv_gelar_akademik_key + 1 }}
                                      </td>
                                      <td>
                                        <b-form-input type="text" v-model="cv_gelar_akademik_value.nama_gelar
                                          "></b-form-input>
                                      </td>
                                      <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="
                                          removeRowGelar(
                                            cv_gelar_akademik_key
                                          )
                                          ">
                                          <i class="bx bx-minus"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td style="width: 170px">PN</td>
                              <td>
                                <b-form-input type="text" v-model="cv_pn"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td style="width: 170px">NIK</td>
                              <td>
                                <b-form-input type="text" v-model="cv_nik" maxlength="16" onkeypress='return event.charCode >= 48 && event.charCode <= 57'></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td style="width: 170px">
                                Tempat , Tanggal Lahir
                              </td>
                              <td>
                                <div class="row mg-5">
                                  <div class="col-md-3">
                                    <b-form-input type="text" v-model="cv_tempat_lahir"></b-form-input>
                                  </div>
                                  <div class="col-md-9">
                                    <b-form-input type="date" v-model="cv_tanggal_lahir"></b-form-input>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td style="width: 170px">Jenis Kelamin</td>
                              <td>
                                <v-select placeholder="Pilih Jenis Kelamin" :options="['Laki - Laki', 'Perempuan']"
                                  v-model="cv_jenis_kelamin"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td style="width: 170px">Agama</td>
                              <td>
                                <v-select placeholder="Pilih Agama" :options="[
                                  'Islam',
                                  'Kristen',
                                  'Katolik',
                                  'Hindu',
                                  'Buddha',
                                  'Konghucu',
                                ]" v-model="cv_agama"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td style="width: 170px">Jabatan Terakhir</td>
                              <td>
                                <b-form-input type="text" v-model="cv_jabatan_terakhir"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td style="width: 170px">Alamat Rumah</td>
                              <td>
                                <b-form-input type="text" v-model="cv_alamat_rumah"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td style="width: 170px">HP</td>
                              <td>
                                <input type="text" v-model="cv_hp" class="form-control" maxlength="15" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                              </td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td style="width: 170px">E-mail</td>
                              <td>
                                <b-form-input type="text" v-model="cv_email"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td>12</td>
                              <td style="width: 170px">NPWP</td>
                              <td>
                                <b-form-input type="text" v-model="cv_npwp"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td>13</td>
                              <td style="width: 170px">Alamat Social Media</td>
                              <td>
                                <b-form-input type="text" v-model="cv_alamat_social_media"></b-form-input>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- SUMMARY/INTEREST/POSISIDIR. -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-user"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">SUMMARY/INTEREST/POSISI DIR.</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="2">
                                II. SUMMARY <br />
                                <em>(Menggambarkan pernyataan misi pribadi &amp;
                                  keahlian atau kompetensi yang dikuasai)</em>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <textarea v-model="cv_summary" id="" style="height: 100px"
                                  class="form-control"></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="2">
                                III. INTEREST <br />
                                <em>(Ilustrasi atas minat/passion yang secara
                                  terus-menerus dikembangkan)</em>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <textarea v-model="cv_interest" id="" style="height: 100px"
                                  class="form-control"></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-bordered table-condensed table-custom">
                          <tbody>
                            <tr style="background: #ddd">
                              <td colspan="8">
                                <b>IV. PERMINTAAN POSISI DIREKTUR</b> <br />
                                <em>(Diperbolehkan memilih lebih dari satu)</em>
                              </td>
                            </tr>
                            <tr>
                              <td>Direktur Utama</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Direktur Utama" />
                              </td>
                              <td>Pemasaran</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Pemasaran" />
                              </td>
                              <td>Investasi</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" :value="Investasi" />
                              </td>
                              <td>Teknologi Informasi</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Teknologi Informasi" />
                              </td>
                            </tr>
                            <tr>
                              <td>Keuangan</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Keuangan"
                                  checked="" />
                              </td>
                              <td>Pengembangan Bisnis</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Pengembangan Bisnis" />
                              </td>
                              <td>Procurement/ Pengadaan</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Procurement/ Pengadaan" />
                              </td>
                              <td>Operasional</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Operasional" />
                              </td>
                            </tr>
                            <tr>
                              <td>Commercial Banking</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Commercial Banking" />
                              </td>
                              <td>Produksi</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Produksi"
                                  checked="" />
                              </td>
                              <td>Keamanan dan Keselamatan Kerja</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Keamanan dan Keselamatan Kerja" />
                              </td>
                              <td>Manajemen Aset</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Manajemen Aset" />
                              </td>
                            </tr>
                            <tr>
                              <td>Consumer Banking</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Consumer Banking" />
                              </td>
                              <td>Teknik</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Teknik" />
                              </td>
                              <td>Logistik</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Logistik" />
                              </td>
                              <td>Pelayanan / Services</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Pelayanan / Services" checked="" />
                              </td>
                            </tr>
                            <tr>
                              <td>Digital Banking</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Digital Banking" />
                              </td>
                              <td>Resiko Perusahaan</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Resiko Perusahaan" />
                              </td>
                              <td>Strategic Portofolio</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Strategic Portofolio" />
                              </td>
                              <td>Kebutuhan / Hukum / Legal</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Kebutuhan / Hukum / Legal" />
                              </td>
                            </tr>
                            <tr>
                              <td>Treasury</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Treasury" />
                              </td>
                              <td>Human Capital (SDM)</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Human Capital (SDM)" />
                              </td>
                              <td>Supply Chain Management</td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur"
                                  value="Supply Chain Management" />
                              </td>
                              <td>
                                Lainnya
                                <input type="text" class="form-control" v-model="permintaan_posisi_lainnya" />
                              </td>
                              <td style="text-align: center; width: 80px">
                                <input type="checkbox" v-model="cv_permintaan_posisi_direktur" value="Lainnya" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- jabatan -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">JABATAN</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">V. RIWAYAT JABATAN</th>
                            </tr>
                            <tr class="bg-light">
                              <th colspan="7">
                                1. Jabatan/ pekerjaan Yang Pernah/ Sedang
                                Diemban
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th style="text-align: center">Jabatan</th>
                              <th>Uraian Singkat Tugas dan Kewenangan</th>
                              <th>Rentang Waktu</th>
                              <th>Achievement (Maksimal 5 Pencapaian)</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addjabatan()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_riwayat_jabatan.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_riwayat_jabatan" :key="index">
                              <td>#</td>
                              <td style="width: 400px">
                                <input type="text" class="form-control" v-model="item.jabatan" />
                              </td>
                              <td style="width: 400px">
                                <input type="text" class="form-control" v-model="item.uraian" />
                              </td>
                              <td style="width: 400px">
                                <input type="text" class="form-control" v-model="item.rentang_waktu" />
                              </td>
                              <td style="width: 400px">
                                <input type="text" class="form-control" v-model="item.achievement" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_riwayat_jabatan' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_riwayat_jabatan',
                                      'file_jabatan'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_riwayat_jabatan_db >= index + 1 &&
                                  cv_riwayat_jabatan_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_riwayat_jabatan' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_riwayat_jabatan' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removejabatan(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                2. Penugasan yang berkaitan dengan jabatan
                                Direksi/ Dewan Komisaris/ Dewan Pengawas
                                <span style="color: red"><em>(bila ada)</em></span>
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Penugasan</th>
                              <th>Tupoksi</th>
                              <th>Rentang Waktu</th>
                              <th>Instansi/Perusahaan</th>
                              <th style="width: 25%">
                                File
                                <br />
                                <small style="color: red">
                                  ({{ textlabel_upload_tipe }}, Ukuran Maksimal Upload:{{ formatBytes(max_upload_size) }})
                                </small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addpenugasan()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_penugasan.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_penugasan" :key="index">
                              <td>#</td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.penugasan" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.tupoksi" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.rentang_waktu" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.instansi" />
                              </td>
                              <td style="width: 400px">
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_penugasan' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_penugasan',
                                      'file_penugasan'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_penugasan_db >= index + 1 &&
                                  cv_penugasan_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_penugasan' + index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_penugasan' + index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removepenugasan(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Organisasi -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">ORGANISASI</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                VI. KEANGGOTAAN ORGANISASI PROFESI/KOMUNITAS
                                YANG DIIKUTI
                              </th>
                            </tr>
                            <tr class="bg-light">
                              <th colspan="7">
                                1. Kegiatan/ organisasi yang pernah/sedang
                                diikuti (yang terkait dengan
                                pekerjaan/profesional)
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Nama Kegiatan/ Organisasi</th>
                              <th>Jabatan</th>
                              <th>Rentang Waktu</th>
                              <th>Uraian Singkat Kegiatan/Organisasi</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addorganisasi1()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_organisasi_pekerjaan.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_organisasi_pekerjaan" :key="index">
                              <td>#</td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.jabatan" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.rentang_waktu" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.uraian" />
                              </td>
                              <td style="width: 400px">
                                <input type="file" :accept="label_upload_tipe" class="form-control" :id="'upload_file_cv_organisasi_pekerjaan' +
                                  index
                                  " @change="
    uploadFileHandle(
      index,
      'cv_organisasi_pekerjaan',
      'file_organisasi_pekerjaan'
    )
    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_organisasi_pekerjaan_db >= index + 1 &&
                                  cv_organisasi_pekerjaan_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_organisasi_pekerjaan' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_organisasi_pekerjaan' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removeorganisasi1(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                2. Kegiatan/ organisasi yang pernah/ sedang
                                diikuti (non formal)
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Nama Kegiatan/ Organisasi</th>
                              <th>Jabatan</th>
                              <th>Rentang Waktu</th>
                              <th>Uraian Singkat Kegiatan/Organisasi</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addorganisasi2()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_organisasi_nonformal.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_organisasi_nonformal" :key="index">
                              <td>#</td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.jabatan" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.rentang_waktu" />
                              </td>
                              <td style="width: 300px">
                                <input type="text" class="form-control" v-model="item.uraian" />
                              </td>
                              <td style="width: 400px">
                                <input type="file" :accept="label_upload_tipe" class="form-control" :id="'upload_file_cv_organisasi_nonformal' +
                                  index
                                  " @change="
    uploadFileHandle(
      index,
      'cv_organisasi_nonformal',
      'file_cv_organisasi_nonformal'
    )
    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_organisasi_nonformal_db >= index + 1 &&
                                  cv_organisasi_nonformal_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_organisasi_nonformal' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_organisasi_nonformal' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removeorganisasi2(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- PENGHARGAAN/PENDIDIKAN -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">PENGHARGAAN/PENDIDIKAN & PELATIHAN</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                VII. PENGHARGAAN<br />
                                (Internal & Eksternal)
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Jenis Penghargaan</th>
                              <th>Tingkat</th>
                              <th>Diberikan Oleh</th>
                              <th>Tahun</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addpenghargaan()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_penghargaan.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_penghargaan" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.jenis" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tingkat" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.diberikan_oleh" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tahun" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_penghargaan' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_penghargaan',
                                      'file_cv_penghargaan'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_penghargaan_db >= index + 1 &&
                                  cv_penghargaan_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_penghargaan' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_penghargaan' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removepenghargaan(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- riwayat pendidikan v2 -->
                        <table class="table table-bordered table-condensed">
                          <tbody>
                            <tr class="bg-light">
                              <td colspan="8">
                                <b>VIII. RIWAYAT PENDIDIKAN DAN PELATIHAN</b>
                              </td>
                            </tr>
                            <tr class="bg-light">
                              <td colspan="8">
                                <b>1. Pendidikan Formal</b> <br />
                                <em>(Dimulai dari Strata 1)</em>
                              </td>
                            </tr>
                            <tr class="bg-light">
                              <th style="text-align: center">No</th>
                              <th style="text-align: center">
                                Jenjang - Penjurusan
                              </th>
                              <th style="text-align: center">
                                Perguruan Tinggi
                              </th>
                              <th style="text-align: center">Tahun Lulus</th>
                              <th style="text-align: center">Kota/Negara</th>
                              <th style="text-align: center">
                                Penghargaan yang didapat
                              </th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th style="text-align: center; width: 1px">
                                <div class="btn btn-success btn-sm" v-on:click="addpendidikan()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </tbody>
                          <tbody id="ktk-pendidikan_formal">
                            <tr v-if="cv_pendidikan_formal.length == 0">
                              <td colspan="8">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_pendidikan_formal" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.jenjang" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.perguruan_tinggi" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tahun_lulus" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.kota" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.penghargaan" />
                              </td>
                              <td>
                                <div class="input-group-btn">
                                  <input type="file" :accept="label_upload_tipe" class="form-control" :id="'upload_file_cv_pendidikan_formal' + index
                                    " @change="
    uploadFileHandle(
      index,
      'cv_pendidikan_formal',
      'file_cv_pendidikan_formal'
    )
    " />
                                  <input type="hidden" v-model="item.file" />
                                  <div v-if="cv_pendidikan_formal_db >= index + 1 &&
                                    cv_pendidikan_formal_db != 0
                                    ">
                                    <div class="respond-input-file float-left" :id="'upload_loading_file_cv_pendidikan_formal' +
                                      index
                                      ">
                                      <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                        <i class="fa fa-download"></i> Download
                                      </a>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div class="respond-input-file float-left" :id="'upload_loading_file_cv_pendidikan_formal' +
                                      index
                                      "></div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button type="button" class="btn btn-danger btn-sm" @click="removependidikan(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr class="bg-light">
                              <td colspan="8">
                                <b>2. Pendidikan dan Latihan/ Pengembangan
                                  Kompetensi Yang Pernah Diikuti (minimal 16
                                  Jam)</b>
                              </td>
                            </tr>
                            <tr class="bg-light">
                              <th style="text-align: center">No</th>
                              <th style="text-align: center">
                                Nama Pendidikan dan Latihan/ Pengembangan
                                Kompetensi
                              </th>
                              <th style="text-align: center" colspan="2">
                                Penyelenggara/ Kota
                              </th>
                              <th style="text-align: center">
                                Lama Diklat/ Pengembangan Kompetensi
                              </th>
                              <th style="text-align: center">
                                Nomor Sertifikasi
                              </th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th style="text-align: center; width: 1px">#</th>
                            </tr>
                            <tr>
                              <td>A.</td>
                              <td colspan="6" style="text-decoration: underline">
                                <b>DIKLAT JABATAN</b>
                              </td>
                              <td>
                                <div class="btn btn-success btn-sm" v-on:click="adddiklat()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody id="ktk-diklat_jabatan">
                            <tr v-if="cv_diklat_jabatan.length == 0">
                              <td colspan="8">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_diklat_jabatan" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td colspan="2">
                                <input type="text" class="form-control" v-model="item.penyelenggara" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.durasi" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nomor_sertifikat" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_diklat_jabatan' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_diklat_jabatan',
                                      'file_cv_diklat_jabatan'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_diklat_jabatan_db >= index + 1 &&
                                  cv_diklat_jabatan_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_diklat_jabatan' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_diklat_jabatan' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td>
                                <button type="button" class="btn btn-danger btn-sm" @click="removediklat(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td>B.</td>
                              <td colspan="6" style="text-decoration: underline">
                                <b>DIKLAT FUNGSIONAL</b>
                              </td>
                              <td>
                                <div class="btn btn-success btn-sm" v-on:click="adddiklatfungsional()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody id="ktk-diklat_fungsional">
                            <tr v-if="cv_diklat_fungsional.length == 0">
                              <td colspan="8">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_diklat_fungsional" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td colspan="2">
                                <input type="text" class="form-control" v-model="item.penyelenggara" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.durasi" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nomor_sertifikat" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control" :id="'upload_file_cv_diklat_fungsional' + index
                                  " @change="
    uploadFileHandle(
      index,
      'cv_diklat_fungsional',
      'file_cv_diklat_fungsional'
    )
    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_diklat_fungsional_db >= index + 1 &&
                                  cv_diklat_fungsional_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_diklat_fungsional' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_diklat_fungsional' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td>
                                <button type="button" class="btn btn-danger btn-sm"
                                  @click="removediklatfungsional(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <span><em>** Tingkat : Organisasi Kerja, Nasional,
                            Internasional</em></span>
                      </div>
                    </b-tab>

                    <!-- KARYATULIS/ACARA/REFERENSI -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">KARYA TULIS/ACARA/REFERENSI</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                IX. KARYA TULIS (dalam 5 tahun terakhir)
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th style="text-align: center; width: 1px">#</th>
                              <th style="text-align: center">
                                Judul dan Media Publikasi
                              </th>
                              <th style="text-align: center">Tahun</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th style="text-align: center; width: 1px">
                                <div class="btn btn-success btn-sm" v-on:click="addkarya()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_karya_tulis.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_karya_tulis" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.judul" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tahun" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_karya_tulis' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_karya_tulis',
                                      'file_cv_karya_tulis'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_karya_tulis_db >= index + 1 &&
                                  cv_karya_tulis_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_karya_tulis' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_karya_tulis' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removekarya(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- X. PENGALAMAN SEBAGAI PEMBICARA/ NARASUMBER/
                                JURI (dalam 5 tahun terakhir) -->
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                X. PENGALAMAN SEBAGAI PEMBICARA/ NARASUMBER/
                                JURI (dalam 5 tahun terakhir)
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Acara / Tema</th>
                              <th>Penyelenggara</th>
                              <th>Periode</th>
                              <th>Lokasi dan Peserta</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addpengalaman()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_pengalaman_pembicara.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_pengalaman_pembicara" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.acara" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.penyelenggara" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.periode" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.lokasi" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control" :id="'upload_file_cv_pengalaman_pembicara' +
                                  index
                                  " @change="
    uploadFileHandle(
      index,
      'cv_pengalaman_pembicara',
      'file_cv_pengalaman_pembicara'
    )
    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_pengalaman_pembicara_db >= index + 1 &&
                                  cv_pengalaman_pembicara_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_pengalaman_pembicara' +
                                    index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_pengalaman_pembicara' +
                                    index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removepengalaman(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- XI. REFERENSI*(yang dapat memberikan keterangan
                                … atas keabsahan) -->
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="7">
                                XI. REFERENSI(yang dapat memberikan keterangan
                                … atas keabsahan)
                              </th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Nama</th>
                              <th>Perusahaan</th>
                              <th>Jabatan</th>
                              <th>Nomor Telp.</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addreferensi()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_referensi.length == 0">
                              <td colspan="7">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_referensi" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.perusahaan" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.jabatan" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.no_telp" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_referensi' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_referensi',
                                      'file_cv_referensi'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_referensi_db >= index + 1 &&
                                  cv_referensi_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_referensi' + index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_referensi' + index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removereferensi(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- keluarga -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">KELUARGA</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="9">XII. KETERANGAN KELUARGA</th>
                            </tr>
                            <tr class="bg-light">
                              <th colspan="9">1. Istri/Suami</th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Nama</th>
                              <th>Tempat Lahir</th>
                              <th>Tanggal Lahir</th>
                              <th>Tanggal Menikah</th>
                              <th>Pekerjaan</th>
                              <th>Keterangan</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addistrisuami()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_pasangan.length == 0">
                              <td colspan="9">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_pasangan" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tempat_lahir" />
                              </td>
                              <td>
                                <input type="date" class="form-control" v-model="item.tanggal_lahir" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tanggal_menikah" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.pekerjaan" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.keterangan" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_pasangan' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_pasangan',
                                      'file_cv_pasangan'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_pasangan_db >= index + 1 &&
                                  cv_pasangan_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_pasangan' + index
                                    ">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_pasangan' + index
                                    "></div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removeistrisuami(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <thead>
                            <tr class="bg-light">
                              <th colspan="9">2. Anak</th>
                            </tr>
                            <tr class="bg-light" style="text-align: center">
                              <th>#</th>
                              <th>Nama</th>
                              <th>Tempat Lahir</th>
                              <th>Tanggal Lahir</th>
                              <th>Jenis Kelamin</th>
                              <th>Pekerjaan</th>
                              <th>Keterangan</th>
                              <th style="width: 25%">
                                File <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </th>
                              <th>
                                <div class="btn btn-success btn-sm" v-on:click="addanak()">
                                  <i class="bx bx-plus"></i>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="cv_anak.length == 0">
                              <td colspan="9">TIdak Ada Data</td>
                            </tr>
                            <tr v-for="(item, index) in cv_anak" :key="index">
                              <td>#</td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nama" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.tempat_lahir" />
                              </td>
                              <td>
                                <input type="date" class="form-control" v-model="item.tanggal_lahir" />
                              </td>
                              <td>
                                <v-select placeholder="Pilih Jenis Kelamin" :options="['Laki-Laki', 'Perempuan']"
                                  v-model="item.jenis_kelamin"></v-select>
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.pekerjaan" />
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.keterangan" />
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'upload_file_cv_anak' + index" @change="
                                    uploadFileHandle(
                                      index,
                                      'cv_anak',
                                      'file_cv_anak'
                                    )
                                    " />
                                <input type="hidden" v-model="item.file" />
                                <div v-if="cv_anak_db >= index + 1 && cv_anak_db != 0
                                  ">
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_anak' + index">
                                    <a :href="item.file" target="_blank" class="btn btn-info btn-sm mt-1">
                                      <i class="fa fa-download"></i> Download
                                    </a>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="respond-input-file float-left" :id="'upload_loading_file_cv_anak' + index">
                                  </div>
                                </div>
                              </td>
                              <td class="text-center">
                                <button type="button" class="btn btn-danger btn-sm" @click="removeanak(index)">
                                  <i class="fa fa-minus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Catatan -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">CATATAN</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <tbody>
                            <tr>
                              <td>
                                <textarea v-model="cv_catatan" class="form-control"></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="col-md-12 text-end">
                          <b-button type="submit" variant="primary" class="btn-sm m-1"><i class="fa fa-save"></i>
                            SIMPAN</b-button>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <router-link :to="{ name: 'cv' }" class="btn bg-secondary text-white">
                    <i class="fa fa-chevron-left"></i> Kembali Ke List surat
                  </router-link>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
  <!-- modal tambah  -->
  <b-modal v-model="AddModal" title="Akses" title-class="text-black font-18" body-class="p-3" hide-footer>
    <form @submit.prevent="StoreDataAkses">
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-3" label="CV ini dapat diubah oleh :" label-for="formrow-nama_posisi-input">
            <v-select v-model="master_user_selected" :options="master_user" label="name" multiple></v-select>
          </b-form-group>
        </div>
        <div class="text-end pt-5 mt-3">
          <b-button variant="light" @click="AddModal = false">Close</b-button>
          <b-button type="submit" variant="success" class="ms-1">Save</b-button>
        </div>
      </div>
    </form>
  </b-modal>
  <!-- modal tambah  -->
  <b-modal v-model="QRCodeModal" title="QR Code" title-class="text-black font-18" body-class="p-3" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div v-if="barcode_link">
          <div class="text-center">
            <img v-bind:src="barcode_link" width="250" />
          </div>
        </div>
        <div v-else><i class="fas fa-spinner fa-spin"></i> Loading...</div>
      </div>
      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="QRCodeModal = false">Close</b-button>
      </div>
    </div>
  </b-modal>
</template>
